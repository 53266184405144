import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsPage = () => (
  <Layout>
    <Seo title="Terms &amp; Conditions" />

    <section className="main--terms">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2>Terms &amp; Conditions</h2>
            <p>
              <strong>These TERMS AND CONDITIONS</strong> (&ldquo;Terms&rdquo;)
              apply to and govern all products, Software and Services provided
              by Vigilant LLC and its Affiliates (&ldquo;Vigilant&rdquo;) and
              any Person who uses any Vigilant products, Software and Services
              (&ldquo;Customer&rdquo;). Vigilant reserves the right to revise or
              otherwise modify these Terms at any time with or without notice to
              Customer. If revised or modified, the revised Terms will be posted
              on this website and will thereafter be immediately effective for
              all later agreements between Vigilant and Customer or any
              subscription agreements associated with any prior agreement
              entered into between the Parties.
            </p>
            <p>
              <strong>SERVICES</strong>
            </p>
            <ol>
              <li>
                <p>
                  <strong>SUBSCRIPTION AGREEMENTS</strong>
                </p>
                <ol>
                  <li>
                    Vigilant shall, in accordance with all terms and conditions
                    set forth in the Agreement, provide Customer the services
                    described in the Agreement (&ldquo;Services&rdquo;).
                  </li>
                  <li>
                    The Agreement will be effective only when signed by Customer
                    and Vigilant. Any modifications or changes to any Agreement
                    or the Services will be effective only by a written change
                    order signed by both Parties.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>LIMITATIONS ON VIGILANT&rsquo;S OBLIGATIONS</strong>
                </p>
                <ol>
                  <li>
                    <strong>
                      Services are performed by internal team members of
                      Vigilant.
                    </strong>{" "}
                    Customer specifically acknowledges, however, that certain
                    aspects of the Services may be impacted or controlled by
                    third parties (i.e. an{" "}
                    <strong>
                      ISP controls Internet connection and availability
                    </strong>
                    ), and actions or inactions by third parties can impair or
                    disrupt <strong>Vigilant’s ability to perform</strong> the
                    Services. Vigilant will use commercially reasonable efforts
                    to remedy and avoid any impaired or disrupted Services{" "}
                    <strong>
                      and will notify Customer in the event Vigilant becomes
                      aware of any such disruption. Notwithstanding, Customer
                      will likely be the first to become aware of any such
                      disruption in Services (i.e., a server is down, internet
                      is disrupted, etc.), and Customer will promptly notify
                      Vigilant in such event.
                    </strong>{" "}
                    Vigilant cannot and does not guarantee that the Services
                    will not be impaired or disrupted by Customer or third
                    parties. Customer acknowledges that its performance of all
                    of its obligations under the Agreement is necessary for
                    Vigilant’s successful performance of the Services. Any
                    failure by Customer to perform its obligations, that in
                    Vigilant’s reasonable judgment prevents or materially
                    hinders Vigilant’s performance of the Services, constitutes
                    a material breach of the Agreement.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>CUSTOMER ENVIRONMENT</strong>
                </p>
                <p>
                  Customer acknowledges that the configuration of
                  Customer&rsquo;s environment may require Vigilant to alter the
                  standard deployment of its Services and may affect the
                  effectiveness or accuracy of the Services. Customer shall
                  promptly notify Vigilant of any changes to its software,
                  hardware, or configuration of the Customer environment that
                  may impact Vigilant&rsquo;s performance of the Services. At
                  times, Vigilant may make recommendations to Customer as part
                  of Services that may require changes to Customer&rsquo;s
                  environment. Customer is solely responsible for any changes
                  made or not made to its environment during the term of any
                  Agreement. Vigilant may monitor, intercept and review
                  activities of Customer employees, contractors and other
                  individuals with access to Customer IT resources and
                  communications systems, including but not limited to email
                  (both outgoing and incoming), instant messages and internet
                  postings and activities. This might include, without
                  limitation, the monitoring, intercepting, accessing,
                  recording, disclosing, inspecting, reviewing, retrieving and
                  printing of transactions, messages, communications, postings,
                  log-ins, recordings and other uses of the systems as well as
                  keystroke capturing and other network monitoring technologies.
                  Vigilant may also store copies of such data and communications
                  for a period of time. Customer consents to such activities by
                  Vigilant.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    USER DATA AND FORENSIC DATA RETENTION AND PRIVACY
                  </strong>
                </p>
                <ol>
                  <li>
                    Your signature below expressly grants Vigilant the right to
                    monitor all network and host related traffic on Customer
                    networks and to retain a copy of all information captured by
                    Vigilant from Customer systems and networks (&ldquo;Captured
                    Data&rdquo;). Any Captured Data, which in Vigilant&rsquo;s
                    sole discretion, is identified as malware, forensic
                    threat-specific data, or is in any way related to a
                    Compromise or Incident shall be separately designated as
                    &ldquo;Threat Data.&rdquo; Customer grants Vigilant, a
                    worldwide, perpetual, irrevocable, non-exclusive,
                    royalty-free license to duplicate, sell, transmit, publish,
                    modify, distribute, publicly display, summarize, alter,
                    compile and create derivative works of any Threat Data.
                    Before sharing Threat Data with a third party, Vigilant will
                    undertake commercially reasonable and good faith efforts to
                    determine if any Customer Confidential Information is
                    contained in or attached to the Threat Data and, if so, will
                    undertake the following steps before proceeding:
                    <ol>
                      <li>
                        undertake reasonable efforts to remove Customer
                        Confidential Information from the Threat Data unless
                        required to remain for analysis by Vigilant or as
                        required by law; and
                      </li>
                      <li>
                        except as required by law, before Threat Data that
                        includes Customer Confidential Information is submitted
                        to any third party, Vigilant will provide Customer the
                        full submission sample for Customer&rsquo;s prior
                        approval.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Vigilant may be exposed to or collect the Private
                    Information of Customer and Customer employees, contractors
                    and agents during its performance of Services or its
                    retention of Forensic Data. To the extent Vigilant is
                    exposed to or collects Private Information or Customer
                    provides Private Information to Vigilant, it shall be
                    Customer&rsquo;s responsibility to identify such information
                    and ensure both Parties&rsquo; compliance with regard to all
                    applicable privacy Laws, regulations and restrictions,
                    including all data privacy Laws and all related license
                    requirements. Customer represents and warrants that (i) it
                    has obtained all necessary permissions and documents,
                    including consent forms, licenses, agreements and
                    certifications, necessary for Vigilant to perform the
                    Services in a manner consistent with Law; and (ii) Customer
                    complies with all applicable laws for processing and
                    transferring Customer Information to Vigilant. Vigilant may
                    disclose Customer Information, including Private Information
                    and Confidential Information, where Vigilant reasonably
                    believes it is required by law to make such disclosure.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>ALERTING AND REPORTING</strong>
                </p>
                <ol>
                  <li>
                    Vigilant shall through 24x7x365 Services monitor, evaluate
                    and investigate events generated by the Services. Vigilant
                    shall provide an analysis and response to each alert or
                    category of alert to Customer pursuant to the Agreement, or
                    according to the Service Level Agreement Response Times
                    outlined in 5.2. If Vigilant determines that an alert is a
                    “false positive” or that it otherwise poses no legitimate
                    threat to Customer’s environment, Vigilant shall have no
                    obligation. to provide an alert analysis.
                  </li>
                  <li>
                    Service Level Agreement Response Times:
                    <ol>
                      <li>
                        Critical – (Covers Identified or imminent /critical
                        threat(s) to an organization&rsquo;s Mission Critical
                        systems and any critical P1 down operational outages
                        that require immediate resolution 24x7). Critical Issues
                        on inbound/outbound issues carry an immediate to 2 hours
                        response time. On Critical issues Vigilant will host an
                        open ended conference bridge to collaborate and provide
                        continuous interaction until resolution.
                      </li>
                      <li>
                        High – (Covers Potential threat(s) to an organization
                        and any Potential operational outage or service outage
                        to user(s) or Mission Critical Systems). The response
                        time for this High level events is 4 hours 24x7.
                      </li>
                      <li>
                        Medium – Any general change request of a non-critical
                        nature however is considered medium level. The response
                        time for this level is 8 hours and is available M-F.
                      </li>
                      <li>
                        Low – Low priority change requests and or information
                        changes and needs are considered to be low level
                        response. Our response time is 24 hours in this
                        category, Monday through Friday only.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Communicating initial threat information will be via phone,
                    email, or a unique Customer portal. Alert details will only
                    contain information available to Vigilant. For example,
                    actual source IP address for an alert may be obscured from
                    Vigilant by infrastructure within the customer environment
                    such as proxy servers. Determination of actual source of an
                    alert may require additional investigation by the Customer
                    and Vigilant. Analysis performed beyond alert notification
                    is considered &ldquo;Incident Response.&rdquo; Unless
                    expressly stated in the Agreement, Vigilant is not
                    responsible to provide Incident Response.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Penetration Testing</strong>
                </p>
                <ol>
                  <li>
                    Authorization
                    <ol>
                      <li>
                        Customer agrees that it has authorized Vigilant to
                        perform penetration testing against their owned
                        applications and warrants Customer has authority to
                        request such services. This testing will include various
                        types of network and application based attacks and can
                        included scanning of all Customer systems, to attack
                        Customer infrastructure for the purposes of identifying
                        information security vulnerabilities in service of the
                        Customer, to perform conduct within and outside of the
                        Customer infrastructure that would otherwise be illegal
                        activity if not already expressly permitted by Customer
                        and to provide any other services to Customer as
                        identified in any statement of work or as described in
                        the invoice above (collectively, the
                        &ldquo;Services&rdquo;). The work will be performed
                        remotely and no physical access to the client
                        environment will be needed. The deliverable of the
                        penetration test report will be delivered no more than 2
                        weeks after the test completion date
                      </li>
                    </ol>
                  </li>
                  <li>
                    Additional Penetration Testing Indemnification
                    <ol>
                      <li>
                        Customer acknowledges and accepts that Vigilant, in its
                        performance of the Services, may possibly harm or
                        otherwise interfere with Customer infrastructure,
                        systems, data, or property. Customer, on behalf of
                        itself and its successors, assigns and other legal
                        representatives, hereby absolutely, unconditionally and
                        irrevocably covenant and agree that it will hold
                        Vigilant harmless and will not sue Vigilant (at law, in
                        equity or otherwise) for any harm or loss incurred by
                        Customer that arises or in any way relates to
                        Vigilant&rsquo;s provision of the Services, except for
                        any such actions by Vigilant that are held to be
                        negligent or reckless by a court of appropriate
                        jurisdiction. Customer agrees to indemnify and hold
                        Vigilant harmless for any liability, loss, cost, damage
                        or expense, including reasonable attorney&rsquo;s fees,
                        resulting from third-party claims against Vigilant when
                        inaccurate information or direction provided by Customer
                        causes Vigilant to take any action in performing the
                        Services that violate applicable law, contract or any
                        third-party&rsquo;s rights. Customer acknowledges that
                        Vigilant is engaged in the business of providing
                        computer security services and associated deliverables
                        to various clients and that none of these arrangements
                        is exclusive.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Data Exfiltration
                    <ol>
                      <li>
                        Any sensitive data located will be documented and
                        testing will be performed to see if the data can be
                        transferred undetected to the test machine&rsquo;s
                        encrypted hard drive.
                      </li>
                      <li>
                        Sensitive data will be handled under the proper
                        requirements governed by the Customer's compliance
                        requirements.
                      </li>
                      <li>
                        Any Sensitive data will be destroyed post assessment and
                        a document of destruction will be given to the Customer
                        on the completion of the assessment.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Clean Up Installed Software and Tools
                    <ol>
                      <li>
                        The tester will remove any software or other artifacts
                        used during testing so that the systems are all returned
                        to their pre-test state.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Customer Requirements
                    <ol>
                      <li>
                        Customer will guarantee that all sites and systems
                        designated for scanning are available. If delay is
                        caused by the site(s) or systems not being ready
                        additional charges will apply at a rate of $500.00 per
                        resource. If this takes place Vigilant project manager
                        and Customer contact will work to agree upon a
                        reasonable and mutually acceptable time to reschedule
                        work.
                      </li>
                      <li>
                        It may be required for the Customer to provide Hardware
                        or Software to Vigilant in order for Vigilant to perform
                        an accurate and complete test. If Hardware or Software
                        from the Customer are required the request will be made
                        by Vigilant to the Customer in writing. All hardware and
                        software provided by Customer will be assumed to be good
                        and in working order. Customer is responsible for all
                        software licensing requirements, software compatibility
                        with hardware or hardware requirements by software and
                        its functionality if applicable. All software and
                        hardware will be returned to the client after the
                        assessment. If the assessment includes destruction of
                        the hardware or software Customer acknowledges that the
                        hardware and software may not be returned in working
                        order and or the state it was delivered to Vigilant in.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Assessment Hours
                    <ol>
                      <li>
                        Unless otherwise defined all assessment work will be
                        performed during normal business hours 8-5 M-F unless
                        designated in writing by Customer. Customer will
                        designate a single point of contact to whom all Vigilant
                        communications may be addressed and who has the
                        authority to act on all aspects of the services
                        throughout the duration of the project; such contact
                        shall be available during normal hours of business
                        (Monday through Friday, 8:00am to 5:00pm local site
                        time, excluding holidays)
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              <strong>GRANT OF LICENSE</strong>
            </p>
            <ol>
              <li>
                <p>
                  <strong>LICENSE GRANT</strong>
                </p>
                <p>
                  Vigilant grants to Customer, exercisable solely by Authorized
                  Users, a non-exclusive and non-transferable right and license
                  to use the Software and Services during the Term. Customer
                  shall use the Software and Services solely for internal
                  business purposes and as set forth in the Agreement. Customer
                  shall have transfer, assignment or sublicense rights absent
                  express written consent from Vigilant. Customer acknowledges
                  and agrees that Customer is solely responsible for ensuring
                  that Authorized Users comply with the Agreement and Customer
                  shall be solely responsible for any breach of the Agreement
                  due to the action or inaction of any Authorized User or of any
                  employee, agent, customer, or contractor of Customer. Customer
                  shall and shall not permit any third party to: (i) remove of
                  modify any markings or reference to Vigilant or its
                  licensors&rsquo; proprietary rights from Vigilant Software or
                  Services; (ii) make the Software, Services, or any materials
                  thereof available in any manner to any third party for use in
                  the third party&rsquo;s business operations; (iii) download,
                  reproduce, copy, republish, alter, adapt, modify, improve,
                  translate, create derivative works from, reverse engineer any
                  part of the Services or Software, disassemble, decompile or
                  otherwise attempt to reveal the trade secrets, know-how, or
                  other Intellectual Property Rights underlying or included in
                  the Software or Services; (iv) access or use the Software or
                  Services in order to build or support and/or assist a third
                  party in building or supporting, products or services
                  competitive to Vigilant; (v) interfere in any manner with the
                  hosting of the Software or the Services; (vi) license, sell,
                  rent, lease, transfer, assign, distribute, display, host,
                  outsource, disclose, permit time-sharing, or otherwise
                  commercially exploit or make the Services, Software, or
                  related materials available to any third party; or (vii)
                  otherwise use the Services or Software not in accordance with
                  the Agreement. Upon expiration or termination of the
                  Agreement, for any reason, Customer&rsquo;s right to access or
                  use the Software and Services shall immediately terminate.
                </p>
              </li>
              <li>
                <p>
                  <strong>LICENSE RESTRICTIONS</strong>
                </p>
                <p>
                  Vigilant will assign to each Authorized User a unique password
                  and identification name (&ldquo;Authorized User
                  Credentials&rdquo;). Authorized User Credentials allow the
                  Authorized User to access the administrative components of the
                  Software and Services. Customer must immediately inform
                  Vigilant if it discovers or reasonably believes that
                  Authorized User Credentials or any other security mechanism
                  issued by Vigilant is lost or forgotten, has or is likely to
                  become known to someone not authorized to use it or is being
                  or is likely to be used in an unauthorized way. In such an
                  event, Customer must complete such security checks as Vigilant
                  deems appropriate. Vigilant reserves the right to suspend
                  access to the Software and Services if at any time Vigilant
                  considers that there is or is likely to be a breach of
                  security or if Customer fails to comply with Vigilant&rsquo;s
                  instructions or requests in relation to security matters.
                </p>
              </li>
              <li>
                <p>
                  <strong>LIMITED LICENSE FORM THIRD PARTIES</strong>
                </p>
                <p>
                  Vigilant may use software/hardware for the Services that are
                  subject to shrink-wrap, click-through, on-screen, open source,
                  or similar licensing arrangements that Customer must timely
                  accept during Vigilant performance of the Services. Vigilant
                  shall obtain Customer&rsquo;s approval prior to accepting the
                  terms of such agreements on behalf of Customer if acceptance
                  of such agreement will require payment from Customer. Where
                  applicable, Vigilant will convey to Customer the requisite
                  license rights to software used by Vigilant to provide the
                  Services. Customer shall either directly pay all costs of all
                  such software/hardware, including all licensing fees, or
                  promptly reimburse Vigilant for all such costs.
                </p>
              </li>
              <li>
                <p>
                  <strong>OPEN SOURCE SOFTWARE LICENSE</strong>
                </p>
                <p>
                  The Software and Services may utilize one or more open source
                  packages. This open source software is governed by the terms
                  and conditions of the applicable open source license and
                  Customer agrees that it will be bound by the terms and
                  conditions of the applicable open source license in connection
                  with its use and distribution of the open source software in
                  the Software and Services. The open source software, the
                  applicable open source licenses and other open source notices
                  will be made available upon request.
                </p>
              </li>
              <li>
                <p>
                  <strong>TERM AND TERMINATION</strong>
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>TERM AND RENEWAL</strong>
                    </p>
                    <p>
                      The Agreement shall commence on the Effective Date and
                      remain in effect for the term identified in the Agreement
                      (the &ldquo;Initial Term&rdquo;). If no Initial Term is
                      identified in the Agreement, the Initial Term shall be
                      deemed to be three (3) years from the Effective Date and
                      shall automatically renew for successive three (3) year
                      periods (each a &ldquo;Renewal Term&rdquo;), unless either
                      Party gives at least ninety (90) days prior written notice
                      of intent to cancel to the other Party before the
                      expiration of the then current term in effect (including
                      the Initial Term or any Renewal Term, as the case may be).
                      Vigilant reserves the right to increase service and
                      product rates at the time of renewal. The Initial Term and
                      any Renewal Term are referred to collectively as, the
                      &ldquo;Term.&rdquo;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>TERMINATION</strong>
                    </p>
                    <p>
                      Vigilant may terminate the Agreement immediately if in
                      Vigilant&rsquo;s sole discretion Customer misuses the
                      Software or Services, engages in illegal practices, or
                      otherwise materially breaches the Agreement. Vigilant may
                      also terminate the Agreement at any time, for any cause,
                      upon thirty (30) days prior written notice. Customer may
                      terminate the Agreement if Vigilant materially breaches
                      the terms of the Agreement and fails to cure such breach
                      within thirty (30) days of written notice.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>EFFECT OF TERMINATION OR EXPIRATION</strong>
                    </p>
                    <p>
                      Upon and after the termination or expiration of any
                      Agreement for any or no reason:
                    </p>
                    <ol>
                      <li>
                        Customer shall immediately cease use of all Vigilant
                        Materials and Confidential Information.
                      </li>
                      <li>
                        Subject to continuing rights, licenses and obligations
                        of either Party under the Agreement, all licenses
                        granted hereunder will immediately terminate and the
                        Parties shall cease all activities concerning, including
                        all use of, in the case of Customer, the expired or
                        terminated Services and/or Software and related Vigilant
                        Materials and, in the case of Vigilant, the Customer
                        Information.
                      </li>
                      <li>
                        Vigilant shall immediately cease use of Customer&rsquo;s
                        Confidential Information except to the extent that such
                        Confidential Information is, in Vigilant&rsquo;s
                        reasonable opinion, inseparable from the Forensic Data
                        Vigilant is entitled to retain and use under the
                        Agreement. In such circumstances, Vigilant shall have no
                        obligation to cease use of or destroy the inseparable
                        Confidential Information.
                      </li>
                      <li>
                        Customer shall pay to Vigilant, (i) all charges and
                        amounts due and payable to Vigilant, if any, for
                        Services performed under the terminated or expired
                        Agreement; (ii) all reimbursements for costs/expenses
                        incurred by Vigilant during the term of the Agreement;
                        (iii) any deferred payments; (iv) 100% of any rate
                        reduction or discount previously granted if the
                        Agreement or any Agreement is terminated before the end
                        of the Term. Customer shall not be entitled to any
                        refund of any payments made by Customer.
                      </li>
                      <li>
                        Each Party shall, at the other Party&rsquo;s option and
                        upon its written request and subject to any retention
                        rights in the Agreement: (i) promptly return or destroy
                        and erase from all systems it directly or indirectly
                        uses or controls all originals and copies of all of the
                        other Party&rsquo;s Confidential Information; and (ii)
                        provide a written statement to the other Party
                        certifying that it has complied with the requirements of
                        this section.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>SURVIVAL</strong>
                </p>
                <p>
                  The provisions set forth in the following sections and any
                  other right or obligation of the Parties in the Agreement
                  that, by its nature, should survive termination or expiration
                  of the Agreement, will survive any expiration or termination
                  of the Agreement: Section 3.3, Section 4, Section 6, Section
                  7, Section 8, Section 9, Section 10, Section 12 and Section
                  13.
                </p>
              </li>
              <li>
                <p>
                  <strong>FEES, TAXES and EXPENSES</strong>
                </p>
                <ol>
                  <li>
                    Subject to the terms of the Agreement, Customer shall pay
                    the fees set forth in the Agreement, which shall be
                    determined and invoiced by Vigilant in accordance with the
                    rates, pricing and discounts set forth in the Agreement
                    (&ldquo;Fees&rdquo;).
                  </li>
                  <li>
                    All Fees and amounts set forth in the Agreement are
                    exclusive of taxes. Customer shall be solely responsible for
                    all sales, service, value-added, use, excise, consumption
                    and any other taxes, duties and charges of any kind, if any,
                    imposed by any governmental entity on any amounts payable by
                    Customer under the Agreement, other than any taxes imposed
                    on, or with respect to, Vigilant income, revenues, gross
                    receipts, personnel, real or personal property or other
                    assets.
                  </li>
                  <li>
                    Customer shall reimburse Vigilant for direct, documented,
                    out-of-pocket expenses incurred by Vigilant in performing
                    the Services, provided that Customer shall only be obligated
                    to reimburse Vigilant for travel and lodging expenses
                    approved in advance by Customer.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>OWNERSHIP</strong>
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>CUSTOMER INFORMATION</strong>
                    </p>
                    <p>
                      Customer will provide Customer Information to Vigilant in
                      connection with the Agreement and Services. Customer will
                      remain the sole and exclusive owner of all right, title
                      and interest in and to all Customer Information, including
                      all Intellectual Property Rights relating thereto, subject
                      only to the licenses granted in the Agreement. Subject to
                      the terms and conditions of the Agreement, Customer hereby
                      grants Vigilant a limited, royalty-free, fully-paid up,
                      non-exclusive, transferable and sublicensable license to
                      Process the Customer Information solely as reasonably
                      necessary to provide the Services and as reasonably
                      necessary to exercise any of the rights granted to
                      Vigilant under the Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>VIGILANT MATERIALS</strong>
                    </p>
                    <p>
                      Vigilant is and will remain the sole and exclusive owner
                      of all right, title and interest in and to the Vigilant
                      Materials, including all Intellectual Property Rights
                      relating thereto, subject only to any license expressly
                      granted to Customer in the Agreement. Excepting any
                      limited license expressly provided in the Agreement,
                      nothing contained in the Agreement shall be construed as
                      granting Customer, or any third party, any right, title,
                      or interest in or to any Vigilant Materials, in each case
                      whether by implication, estoppel or otherwise.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>CONFIDENTIALITY</strong>
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>CONFIDENTIAL INFORMATION</strong>
                    </p>
                    <p>
                      In connection with the Agreement and Services, each Party
                      (as the &ldquo;Disclosing Party&rdquo;) may disclose or
                      make available Confidential Information to the other Party
                      (as the &ldquo;Receiving Party&rdquo;). Subject to the
                      exclusions listed below, &ldquo;Confidential
                      Information&rdquo; means information in any form or medium
                      (whether oral, written, electronic or other) that the
                      Disclosing Party reasonably considers confidential or
                      proprietary, including information consisting of or
                      relating to the Disclosing Party technology, trade
                      secrets, know-how, business operations, plans, strategies,
                      customers and pricing and information with respect to
                      which the Disclosing Party has contractual or other
                      confidentiality obligations that is either marked,
                      designated or otherwise identified as
                      &ldquo;confidential&rdquo; or that should reasonably be
                      understood to be confidential considering the
                      circumstances of its disclosure. Without limiting the
                      foregoing, (i) all Customer Information is and will remain
                      the Confidential Information of Customer and (ii) all
                      Vigilant Materials including the Software, Specifications
                      and Documentation are and will remain the Confidential
                      Information of Vigilant and (iii) the terms of the
                      Agreement are the Confidential Information of Vigilant.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>EXCLUSIONS</strong>
                    </p>
                    <p>
                      Confidential Information does not include information
                      that: (i) was rightfully known to the Receiving Party
                      without restriction on use or disclosure prior to such
                      information being disclosed or made available to the
                      Receiving Party in connection with the Agreement; (ii) was
                      or becomes generally known by the public other than by the
                      Receiving Party&rsquo;s or any of its employees, agents,
                      contractors, or other representatives&rsquo; noncompliance
                      with the Agreement; (iii) was or is received by the
                      Receiving Party on a non-confidential basis from a third
                      party that was not or is not, at the time of such receipt,
                      under any obligation to maintain its confidentiality; or
                      (iv) was or is independently developed by the Receiving
                      Party without reference to or use of any Confidential
                      Information.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>SAFEGUARD</strong>
                    </p>
                    <p>
                      As a condition to being provided with any disclosure of or
                      access to Confidential Information, the Receiving Party
                      (i) shall not access or use, or permit the access or use
                      of Confidential Information other than as necessary to
                      exercise its rights or perform its obligations under and
                      in accordance with or as expressly authorized by the
                      Agreement and (ii) shall safeguard the Confidential
                      Information from unauthorized use, access or disclosure
                      using at least the degree of care it uses to protect its
                      own similarly sensitive information and in no event less
                      than a reasonable degree of care.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>COMPELLED DISCLOSURES</strong>
                    </p>
                    <p>
                      If the Receiving Party or any of its representatives is
                      compelled by applicable Law to disclose any Confidential
                      Information then, to the extent permitted by applicable
                      Law, the Receiving Party shall: (i) promptly and prior to
                      such disclosure, notify the Disclosing Party in writing of
                      such requirement so that the Disclosing Party can seek a
                      protective order or other remedy, or waive its rights; and
                      (ii) provide reasonable assistance to the Disclosing
                      Party, at the Disclosing Party&rsquo;s sole cost and
                      expense, in opposing such disclosure or seeking a
                      protective order or other limitations on disclosure. If
                      the Disclosing Party waives compliance or, after providing
                      the notice and assistance required under this paragraph,
                      the Receiving Party remains required by law to disclose
                      any Confidential Information, the Receiving Party shall
                      disclose only that portion of the Confidential Information
                      that the Receiving Party is legally required to disclose
                      and, upon the Disclosing Party&rsquo;s request, shall use
                      commercially reasonable efforts to obtain assurances from
                      the applicable court or other presiding authority that
                      such Confidential Information will be afforded
                      confidential treatment. No such compelled disclosure by
                      the Receiving Party will otherwise affect the Receiving
                      Party&rsquo;s obligations hereunder with respect to the
                      Confidential Information so disclosed.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>INDEMNIFICATION</strong>
                </p>
                <ol>
                  <li>
                    <p>
                      Customer shall indemnify, hold harmless and defend
                      Vigilant and its managers, officers, directors, employees,
                      agents, Affiliates, contractors, successors and assigns,
                      from and against any losses, damages, liabilities,
                      deficiencies, claims, actions, suits, judgments,
                      settlements, interest, awards, penalties, fines, costs or
                      expenses of whatever kind, including professional fees and
                      reasonable attorneys&rsquo; fees incurred by Vigilant that
                      arise out of any direct or third-party claim alleging: (i)
                      breach or non-fulfillment of any representation, warranty,
                      or covenant this Agreement by Customer or its managers,
                      officers, directors, employees, agents, Affiliates,
                      contractors, successors and assigns
                      (&ldquo;Personnel&rdquo;); (ii) any negligent or more
                      culpable act or omission of Customer or its Personnel
                      (including any reckless or willful misconduct) in
                      connection with the performance of its obligations under
                      this Agreement; (iii) any use of Customer systems by
                      Vigilant consistent with the Agreement that results in the
                      infringement of any Intellectual Property Rights or other
                      privacy violations; (iv) any bodily injury, death of any
                      person, or damage to real or tangible personal property
                      caused by the negligent or more culpable acts or omissions
                      of Customer or its Personnel (including any reckless or
                      willful misconduct); or (v) any failure by Customer to
                      comply with any applicable federal, state, or local laws,
                      regulations, or codes in the performance of its
                      obligations under this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      If Vigilant believes or it is determined that any of the
                      Software may have violated a third party&rsquo;s
                      Intellectual Property Rights, Vigilant may, at its sole
                      discretion and as Customer&rsquo;s sole remedy for such
                      violation either:
                    </p>
                    <ol>
                      <li>
                        modify or replace the Software to be non-infringing
                        (while substantially preserving its utility or
                        functionality);
                      </li>
                      <li>
                        obtain for Customer a license to allow for continued use
                        of the Software; or
                      </li>
                      <li>
                        end the relevant license, terminate Customer&rsquo;s
                        access to the Software, refund any prepaid fees not yet
                        earned and terminate the Agreement.
                      </li>
                    </ol>
                    <p>
                      Vigilant is not obligated to take any of the
                      above-described actions to the extent claims or actions
                      are based on or result from: (i) modifications or
                      alterations of the Software by Customer; (ii) use of the
                      Software outside the scope of use identified in the
                      Agreement or in Vigilant&rsquo;s user Documentation or
                      policies; or (iii) the combination of the Software with
                      any products or services not provided by Vigilant.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                  IN NO EVENT WILL VIGILANT BE LIABLE UNDER THE AGREEMENT FOR
                  ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR
                  PUNITIVE DAMAGES EVEN IF ADVISED OF OR MADE AWARE OF THE
                  POSSIBILITY OF SUCH DAMAGES. FURTHER, IN NO EVENT SHALL
                  VIGILANT&rsquo;S LIABILITY UNDER THE AGREEMENT EXCEED THE FEES
                  FOR SERVICES PAID BY CUSTOMER UNDER THE AGREEMENT IN THE ONE
                  (1) YEAR PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
                </p>
              </li>
              <li>
                <p>
                  <strong>REPRESENTATIONS AND WARRANTIES; DISCLAIMER.</strong>
                </p>
                <ol>
                  <li>
                    <p>
                      Each Party represents and warrants to the other Party that
                      (i) it is duly organized, validly existing and in good
                      standing as a corporation or other entity under the Laws
                      of the jurisdiction of its incorporation or other
                      organization, (ii) it has the full right, power and
                      authority to enter into and perform its obligations and
                      grant the rights, licenses, consents and authorizations it
                      grants or is required to grant under the Agreement. (iii)
                      the execution of the Agreement by its representative whose
                      signature is set forth at the end of the Agreement has
                      been duly authorized by all necessary corporate or
                      organizational action of such Party; and (iv) when
                      executed and delivered by both Parties, the Agreement will
                      constitute the legal, valid and binding obligation of such
                      Party, enforceable against such Party in accordance with
                      its terms. Customer further represents, warrants and
                      covenants that Customer owns or otherwise has and will
                      have the necessary rights and consents in and relating to
                      the Customer Information so that, as received by Vigilant
                      and used in accordance with the Agreement, they do not and
                      will not infringe, misappropriate or otherwise violate any
                      Intellectual Property Rights, any privacy, or other rights
                      of any third party or violate any applicable law.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>DISCLAIMER OF WARRANTIES</strong>
                      <br />
                      EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN THIS
                      SECTION, ALL SERVICES AND VIGILANT MATERIALS ARE PROVIDED
                      &ldquo;AS IS&rdquo; AND VIGILANT HEREBY DISCLAIMS ALL
                      WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR
                      OTHERWISE and VIGILANT SPECIFICALLY DISCLAIMS ALL IMPLIED
                      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE, TITLE AND NON-INFRINGEMENT and ALL WARRANTIES
                      ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE.
                      WITHOUT LIMITING THE FOREGOING, VIGILANT MAKES NO WARRANTY
                      OF ANY KIND THAT THE SERVICES OR VIGILANT MATERIALS, OR
                      ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET
                      CUSTOMER&rsquo;S OR ANY OTHER PERSON&rsquo;S REQUIREMENTS,
                      OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT,
                      BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER
                      SERVICES, BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL
                      CODE OR ERROR FREE, OR THAT THE SOFTWARE OR SERVICES WILL
                      PROTECT CUSTOMER AGAINST ALL POSSIBLE SECURITY THREATS,
                      INCLUDING INTENTIONAL MISCONDUCT BY THIRD PARTIES OR BY
                      CUSTOMER OR CUSTOMER&rsquo;S PERSONNEL. ALL THIRD-PARTY
                      MATERIALS ARE PROVIDED &ldquo;AS IS&rdquo; AND ANY
                      REPRESENTATION OR WARRANTY OF OR CONCERNING ANY
                      THIRD-PARTY MATERIALS IS STRICTLY BETWEEN CUSTOMER AND THE
                      THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY
                      MATERIALS.
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer agrees to comply with the requirements of all
                      applicable laws, rules and regulations in connection with
                      the Agreement and the Services provided. Vigilant reserves
                      the right to take all actions that it believes necessary
                      to comply with any applicable law, rule, or regulation
                      including immediate termination of the Services without
                      notice to Customer.
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer agrees that Vigilant is not responsible for any
                      delays caused by Customer. Customer further recognizes
                      that the Software and Services involve a risk of loss of
                      or disruption to Internet services, network services,
                      electronic communications, information, Customer
                      Information and data, including, but not limited to, third
                      party access to the foregoing in a manner that may have
                      adverse business consequences for Customer. Customer
                      assumes all risk of loss of, or disruption to, the
                      foregoing and hereby releases Vigilant and its Affiliates
                      from any liability for any such loss including, but not
                      limited to (i) the use or inability to use the Software or
                      any system or application; (ii) any infringement or claim
                      of infringement of a copyright, patent or other
                      Intellectual Property Right allegedly implicated by the
                      Software or Services or any other software acquired in
                      connection with the Services; (iii) any loss of or changes
                      to Customer Information or other data, or the inaccuracy
                      of Customer Information or other data; (iv) the content of
                      Customer Information; (v) delays or failures to perform
                      any obligations hereunder due to a Force Majeure event;
                      (vi) impairments to the Services caused by acts within the
                      control of the Customer, its employees, or its agents;
                      (vii) interoperability of specific Customer applications
                      or systems; (viii) Customer&rsquo;s inability to access or
                      interact with other providers or their services through
                      the Internet; (ix) performance impairments caused on the
                      Internet; (x) the cost of substitute goods, services, or
                      technology; and/or (xi) any business loss, including, but
                      not limited to, lost profits.
                    </p>
                  </li>
                  <li>
                    <p>
                      Customer specifically acknowledges that each type of
                      information security threat or attack presents unique
                      challenges, not all of which are related to technology
                      (e.g., some security breaches may be due to human factors
                      such as an employee divulging their password) and that
                      Vigilant cannot be responsible for monitoring or analyzing
                      items that are not specifically outlined in the Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      Unlimited Breach Response Policy: Under this Subscription
                      Agreement Vigilant provides unlimited breach response
                      forensics at no additional cost to the Customer for any
                      incidents that originated in their environment while under
                      Vigilant&rsquo;s protection. If an incident response is
                      initiated and it is determined that the incident happened,
                      or was associated to an event that preceded Vigilant
                      services, The Customer will incur an incident response
                      service charge. The scope of the unlimited breach response
                      forensics only applies to the capabilities of the services
                      The Customer subscribes to and only for network segments,
                      or endpoints, with Vigilant services installed on them.
                      For example, if The Customer subscribes to MNDR services
                      and not MEDR then incident response forensics would only
                      cover a Customer network and not endpoints and only on the
                      segments of The Customer network(s) traffic that MNDR is
                      configured to monitor. Unlimited breach response for MEDR
                      only applies for Customers that subscribe to MEDR and
                      choose Crowdstrike as the integration and endpoint tool.
                      In order to be eligible for Vigilant&rsquo;s unlimited
                      breach response coverage, Vigilant&rsquo;s implementation
                      teams must certify client environment during the
                      onboarding process of Vigilant services in client
                      environment. Customer will receive a certification
                      document during this process. If it is found that client
                      modified Vigilant service deployments post certification,
                      Vigilant will not be obligated to honor unlimited breach
                      response services and The Customer will be obligated to
                      pay Vigilant for all incident response services provided
                      to Customer.
                    </p>
                    <p>
                      If the Customer's forensic investigation requirements are
                      outside of subscribed services, The Customer may purchase
                      incident response hours from Vigilant to cover said
                      requirements. If the forensic investigation calls for
                      additional data or information, the Customer is
                      responsible for providing access to this data in a secure
                      and timely manner.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>FORCE MAJEURE</strong>
                </p>
                <p>
                  In no event will Vigilant be liable or responsible to
                  Customer, or be deemed to have defaulted under or breached the
                  Agreement, for any failure or delay in fulfilling or
                  performing any term of the Agreement, when and to the extent
                  such failure or delay is caused by any circumstances beyond
                  Vigilant&rsquo;s reasonable control, including acts of God,
                  flood, fire, earthquake or explosion, war, terrorism,
                  invasion, riot or other civil unrest, embargoes or blockades
                  in effect on or after the date of the Agreement, national or
                  regional emergency, strikes, labor stoppages or slowdowns or
                  other industrial disturbances, passage of Law or any action
                  taken by a governmental or public authority, including
                  imposing an embargo, export or import restriction, quota or
                  other restriction or prohibition or any complete or partial
                  government shutdown, or national or regional shortage of
                  adequate power or telecommunications or transportation.
                </p>
              </li>
              <li>
                <p>
                  <strong>MICELLANEOUS</strong>
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>Relationship of the Parties</strong>
                    </p>
                    <p>
                      The relationship between the Parties is that of
                      independent contractors. Nothing contained in the
                      Agreement shall be construed as creating any agency,
                      partnership, joint venture or other form of joint
                      enterprise, employment or fiduciary relationship between
                      the Parties and neither Party shall have authority to
                      contract for or bind the other Party in any manner
                      whatsoever.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Public Announcements</strong>
                    </p>
                    <p>
                      Customer agrees (i) that Vigilant may identify Customer as
                      a recipient of Services and use Customer&rsquo;s name and
                      logo in sales presentations, marketing materials and press
                      releases and (ii) to develop a brief Customer profile for
                      use by Vigilant for promotional purposes on any
                      promotional materials, including web sites and social
                      media, owned and/or controlled by Vigilant.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Notices</strong>
                    </p>
                    <p>
                      Except as otherwise expressly set forth in the Agreement,
                      all notices, requests, consents, claims, demands, waivers
                      and other communications under the Agreement have binding
                      legal effect only if in writing and addressed to, in the
                      case of Customer, the name and address or email address
                      identified on the Agreement or, in the case of Vigilant,
                      to 
                      <a href="mailto:legalteam@vigilantnow.com">
                        legalteam@vigilantnow.com
                      </a>
                       or to such other address or such other person that such
                      Party may designate from time to time in accordance with
                      this paragraph. Notices sent in accordance with this
                      paragraph shall be deemed effectively given: (a) when
                      received, if delivered by hand, with signed confirmation
                      of receipt; (b) when received, if sent by a nationally
                      recognized overnight courier, signature required; (c) when
                      sent, if by e-mail, (with confirmation of transmission),
                      if sent during the addressee&rsquo;s normal business hours
                      and on the next business day, if sent after the
                      addressee&rsquo;s normal business hours; and (d) on the
                      5th day after the date mailed by certified or registered
                      mail, return receipt requested, postage prepaid.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Entire Agreement; Amendments; Waiver</strong>
                    </p>
                    <p>
                      The Agreement, including any other documents incorporated
                      therein by reference, constitutes the sole and entire
                      agreement of the Parties with respect to the subject
                      matter of the Agreement and supersedes all prior and
                      contemporaneous understandings, agreements,
                      representations and warranties, both written and oral,
                      with respect to such subject matter. The Agreement may be
                      amended, modified or changed only by a writing signed by
                      both Parties. The waiver of a breach of any provision of
                      the Agreement will not operate or be interpreted as a
                      waiver of any other or subsequent breach. In the event of
                      any inconsistency between these Terms and the Agreement or
                      between these Terms and any other documents incorporated
                      herein by reference, the following order of precedence
                      governs (other than an exception expressly set forth as
                      such therein): (a) first, these Terms; (b) second, the
                      Agreement; and (c) third, any other documents incorporated
                      herein by reference.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Non-Solicitation</strong>
                    </p>
                    <p>
                      During the Term and for two (2) years following the
                      expiration or termination of any Agreement, Customer
                      agrees not to: (i) directly or through others, solicit or
                      attempt to solicit or hire any Vigilant Personnel; (ii)
                      encourage Vigilant Personnel to terminate his or her
                      relationship with Vigilant; or (iii) hire or directly
                      contract Vigilant Personnel absent Vigilant&rsquo;s
                      express prior written consent.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Restrictive Covenant</strong>
                    </p>
                    <p>
                      Customer acknowledges that a breach or threatened breach
                      of any of the restrictive covenants in the Agreement or
                      these Terms would give rise to irreparable harm to
                      Vigilant, for which monetary damages would not be an
                      adequate remedy and hereby agrees that in the event of a
                      breach or a threatened breach by Customer or its
                      Affiliates of any such obligations, Customer shall, in
                      addition to any and all other rights and remedies that may
                      be available to it in respect of such breach, be entitled
                      to equitable relief, including a temporary restraining
                      order, an injunction, specific performance and any other
                      relief that may be available from a court of competent
                      jurisdiction (without any requirement to post bond).
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Assignment</strong>
                    </p>
                    <p>
                      Customer shall not assign, delegate, or subcontract any
                      portion of its rights, duties, or obligations under the
                      Agreement without the prior written consent of Vigilant.
                      Any purported assignment in violation of this section and
                      shall be null and void. Customer agrees that Vigilant may
                      subcontract services to be performed in connection with
                      the Agreement; provided that any such subcontracting
                      arrangement will not relieve Vigilant of any of its
                      obligations hereunder. Vigilant may assign the Agreement,
                      including its rights and duties hereunder, without the
                      consent of Customer.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Severability</strong>
                    </p>
                    <p>
                      If any provision of the Agreement is invalid, illegal or
                      unenforceable in any jurisdiction, such invalidity,
                      illegality or unenforceability shall not affect any other
                      term or provision of the Agreement or invalidate or render
                      unenforceable such term or provision in any other
                      jurisdiction. Upon such determination that any term or
                      other provision is invalid, illegal or unenforceable, the
                      Parties hereto shall negotiate in good faith to modify the
                      Agreement so as to affect the original intent of the
                      Parties as closely as possible in a mutually acceptable
                      manner in order that the transactions contemplated hereby
                      be consummated as originally contemplated to the greatest
                      extent possible.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Governing Law and Jurisdiction</strong>
                    </p>
                    <p>
                      The Agreement shall be solely and exclusively governed,
                      construed and enforced in accordance with the Laws of the
                      State of Ohio (without regard to the conflicts of Law
                      provisions thereof). The Parties hereby expressly consent
                      to the exclusive jurisdiction and venue of the federal and
                      state courts governing Butler County, Ohio to adjudicate
                      any dispute arising out of the Agreement and hereby waive
                      the affirmative defenses of lack of personal jurisdiction,
                      lack of venue and forum non-conveniens.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Waiver of Jury Trial</strong>
                    </p>
                    <p>
                      Customer irrevocably and unconditionally waives any right
                      it may have to a trial by jury in respect of any legal
                      action arising out of or relating to the Agreement or the
                      transactions contemplated hereby.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>No Third-Party Beneficiary</strong>
                    </p>
                    <p>
                      No third party shall be deemed a third-party beneficiary
                      of the Agreement. Any Customer Affiliate networks may be
                      monitored under the Agreement as long as the Affiliate is
                      wholly owned by Customer, the Affiliate is separately
                      identified in the Agreement and the Affiliate network
                      traffic passes through the same data pipe.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Export Laws</strong>
                    </p>
                    <p>
                      Export Laws of the United States and any other relevant
                      local export Laws apply to the Software. Customer agrees
                      that such export control Laws govern Customer&rsquo;s use
                      of the Software and Services (including technical data)
                      and any deliverables provided under the Agreement.
                      Customer agrees to comply with all such export Laws
                      (including &ldquo;deemed export&rdquo; and &ldquo;deemed
                      re-export&rdquo; regulations). Customer agrees that no
                      data, information, software programs and/or materials
                      resulting from the Services (or direct product thereof)
                      will be exported, directly or indirectly, in violation of
                      such Laws, or will be used for any purpose prohibited by
                      such Laws including, without limitation, nuclear,
                      chemical, or biological weapons proliferation, or
                      development of missile technology.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Audit Rights</strong>
                    </p>
                    <p>
                      Vigilant may, at its sole discretion, audit
                      Customer&rsquo;s use of the Services during the Term.
                      Customer agrees to cooperate in good faith with
                      Vigilant&rsquo;s audit and provide reasonable assistance
                      and access to all information Vigilant deems necessary to
                      complete the audit. Any such audit shall not unreasonably
                      interfere with Customer&rsquo;s normal business
                      operations. If any audit produces use by Customer in
                      excess of the Agreement, Customer agrees to pay, within
                      thirty (30) days of written notification, any fees
                      applicable to Customer&rsquo;s use of the Services in
                      excess of its rights under the Agreement. If Customer does
                      not pay such fees, Vigilant can, at its sole discretion,
                      terminate the Services and/or the Agreement. Customer
                      agrees that Vigilant shall not be responsible for any of
                      Customer&rsquo;s costs incurred in cooperating with the
                      audit.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Non-Applicability of UCITA</strong>
                    </p>
                    <p>
                      The Uniform Computer Information Transactions Act does not
                      apply to the Agreement. Customer understands that Vigilant
                      business partners, including any third-party firms
                      retained by Customer to provide computer consulting
                      services, are independent of Vigilant and are not Vigilant
                      agents. Vigilant is not liable for nor bound by any acts
                      of any such business partner, unless the business partner
                      is providing services as a Vigilant subcontractor on an
                      engagement ordered under the Agreement.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>ADDITIONAL DEFINITIONS</strong>
                </p>
                <ol>
                  <li>
                    <strong>&ldquo;Affiliate&rdquo;</strong> of a Person means
                    any other Person that directly or indirectly, through one or
                    more intermediaries, controls, is controlled by, or is under
                    common control with, such Person. The term
                    &ldquo;control&rdquo; (including the terms &ldquo;controlled
                    by&rdquo; and &ldquo;under common control with&rdquo;) means
                    the direct or indirect power to direct or cause the
                    direction of the management and policies of a Person,
                    whether through the ownership of voting securities, by
                    contract or otherwise/ownership of more than 50% of the
                    voting securities of a Person.
                  </li>
                  <li>
                    <strong>&ldquo;Agreement&rdquo;</strong> means any
                    document(s) executed by the Parties that contemplates,
                    refers to, or otherwise incorporates these Terms, as they
                    may be amended from time to time.
                  </li>
                  <li>
                    <strong>&ldquo;Authorized User&rdquo;</strong> means any
                    employee or third-party user that requires access to the
                    Software or Services and has been identified in writing by
                    Customer to Vigilant and approved by Vigilant as being
                    authorized to use the administrative components of the
                    Software or Services.
                  </li>
                  <li>
                    <strong>&ldquo;Compromise&rdquo;</strong> means any Incident
                    ranging from an individual/small-scale operation (e.g.,
                    insiders, suppliers and activists) to large-scale, organized
                    efforts (e.g., perpetrated by criminal networks and foreign
                    governments).
                  </li>
                  <li>
                    <strong>&ldquo;Customer Information&rdquo;</strong> means
                    any information, records, data, or any other materials (in
                    whatever form) entered into the Software by Customer, or
                    otherwise provided to Vigilant by Customer, any of
                    Customer&rsquo;s Authorized User, or any other employee,
                    agent, contractor, or other representative of Customer.
                  </li>
                  <li>
                    <strong>&ldquo;Disclosing Party&rdquo;</strong> has the
                    meaning set forth in Section 7.1.
                  </li>
                  <li>
                    <strong>&ldquo;Documentation&rdquo;</strong> means any
                    manuals, instructions, or other documents or materials that
                    Vigilant provides or makes available to Customer in any form
                    or medium and which describe the functionality, components,
                    features or requirements of the Services or Vigilant
                    Materials, including any aspect of the installation,
                    configuration, integration, operation, use, support or
                    maintenance thereof.
                  </li>
                  <li>
                    <strong>&ldquo;Effective Date&rdquo;</strong> has the
                    meaning set forth in the Agreement or if no date is
                    identified, such it shall mean the date of the latest
                    signature on the Agreement.
                  </li>
                  <li>
                    <strong>&ldquo;Forensic Data&rdquo;</strong> means any data
                    identified by Vigilant in its sole discretion as malware or
                    forensic data related to malware, including any data which
                    contains embedded malware or is related to any Compromise or
                    Incident.
                  </li>
                  <li>
                    <strong>&ldquo;Incident&rdquo;</strong> means the
                    introduction of malicious software such as trojans, worms,
                    viruses and spyware; password phishing; cyber-attack;
                    cyber-intrusion; hacking; data breach; unauthorized access;
                    denial of service; malware; bots; system Compromise or other
                    computer security breach.
                  </li>
                  <li>
                    <strong>&ldquo;Incident Response&rdquo;</strong> has the
                    meaning set forth in Section 1.5.
                  </li>
                  <li>
                    <strong>&ldquo;Intellectual Property Rights&rdquo;</strong>
                     means any and all registered and unregistered rights
                    granted, applied for or otherwise now or hereafter in
                    existence under or related to any patent, copyright,
                    trademark, trade secret, database protection or other
                    intellectual property rights Laws and all similar or
                    equivalent rights or forms of protection, in any part of the
                    world.
                  </li>
                  <li>
                    <strong>&ldquo;Law&rdquo; or &ldquo;Laws&rdquo;</strong>
                     means any statute, law, ordinance, regulation, rule, code,
                    order, constitution, treaty, common law, judgment, decree or
                    other requirement of any federal, state, local or foreign
                    government or political subdivision thereof, or any
                    arbitrator, court or tribunal of competent jurisdiction.
                  </li>
                  <li>
                    <strong>&ldquo;Parties&rdquo;</strong> means both Vigilant
                    and Customer.
                  </li>
                  <li>
                    <strong>&ldquo;Party&rdquo;</strong> means either Vigilant
                    or Customer individually.
                  </li>
                  <li>
                    <strong>&ldquo;Person&rdquo;</strong> means an individual,
                    corporation, partnership, joint venture, limited liability
                    entity, governmental authority, unincorporated organization,
                    trust, association or other entity.
                  </li>
                  <li>
                    <strong>&ldquo;Personnel&rdquo;</strong> has the meaning set
                    forth in Section 8.1.
                  </li>
                  <li>
                    <strong>&ldquo;Private Information&rdquo;</strong> means
                    information comprising personally identifying or proprietary
                    network information related to Customer and third parties
                    that interact with Customer and Customer&rsquo;s or a third
                    party&rsquo;s network equipment, files, databases, logs and
                    other sources of information which may support the Services.
                  </li>
                  <li>
                    <strong>&ldquo;Process&rdquo;</strong> means to perform any
                    operation or set of operations on any data, information,
                    material, work, expression or other content, including to
                    (a) collect, receive, input, upload, download, record,
                    reproduce, store, organize, combine, log, catalog,
                    cross-reference, manage, maintain, copy, adapt, alter,
                    translate or make other improvements or derivative works,
                    (b) process, retrieve, output, consult, use, disseminate,
                    transmit, submit, post, transfer, disclose or otherwise
                    provide or make available, or (c) block, erase or destroy.
                  </li>
                  <li>
                    <strong>&ldquo;Receiving Party&rdquo;</strong> has the
                    meaning set forth in Section 7.1.
                  </li>
                  <li>
                    <strong>&ldquo;Software&rdquo;</strong> means Vigilant
                    software application or applications and any third-party or
                    other software and all new versions, updates, revisions,
                    improvements and modifications of the foregoing, that
                    Vigilant provides access to, use of, or uses as part of the
                    Services or otherwise related to the Agreement.
                  </li>
                  <li>
                    <strong>&ldquo;Specifications&rdquo;</strong> means the
                    specifications for the Services set forth in the Agreement
                    and, to the extent consistent with and not limiting of the
                    foregoing, the Documentation.
                  </li>
                  <li>
                    <strong>&ldquo;Terms&rdquo;</strong> has the meaning set
                    forth in the preamble.
                  </li>
                  <li>
                    <strong>&ldquo;Threat Data&rdquo;</strong> has the meaning
                    set forth in Section 1.4.
                  </li>
                  <li>
                    <strong>&ldquo;User Data&rdquo;</strong> means any and all
                    information reflecting the access or use of the Services by
                    or on behalf of Customer or any Authorized User which is
                    automatically collected or logged by Vigilant or a third
                    party on Vigilant&rsquo;s behalf, including any end user
                    profile-, visit-, session-, impression-, click through- or
                    click stream- data and any statistical or other analysis,
                    information or data based on or derived from any of the
                    foregoing.
                  </li>
                  <li>
                    <strong>&ldquo;Vigilant Materials&rdquo;</strong> means all
                    devices, documents, data, know-how, methods, processes,
                    software and other inventions, works, technologies and
                    materials, including any and all Services, Software,
                    Documentation, computer hardware, programs, reports and
                    specifications, client software and deliverables that are
                    proprietary to Vigilant and provided or used by Vigilant in
                    connection with performing the Services.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default TermsPage
